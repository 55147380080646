import React, { lazy, useEffect, useState } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';
import { useStore } from './store/storeUtils';
import { generateManifest, modifyTitle, loadCss, loadFavicon } from './utils/HTMLInjector';
import { useMutation, useQuery } from '@apollo/client';
import Client from './client/GraphQLEndpoints';
import { DetectUrl } from './utils/DetectUrl';
import Loader from 'react-loader-spinner';
import { useTracking } from './hooks/useTracking';
import { inizializeAnalytics } from './utils/analytics';
import CookiesUtils from './utils/cookiesUtils';
import JwtChecker from './utils/JwtChecker';
import { loadResources, useTranslation } from '@tecma/i18n';
import Icons from './components/Icons';
import moment from 'moment';
import { getLanguage, getLanguageAndLocale } from './utils/languageUtils';
import CurrencyProvider from './contexts/CurrencyProvider';
import ServiceWorkerWrapper from './components/ServiceWorkerWrapper';

//Loading
const showLoading = () => <div className='animated fadeIn pt-3 text-center'>Loading...</div>;

//Pages
const Login = lazy(() => import('./components/pages/Login'));
const Layout = lazy(() => import('./components/OuterLayout'));
const Page404 = lazy(() => import('./components/pages/Page404'));

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_CODE,
};

inizializeAnalytics(tagManagerArgs);

if (process.env.REACT_APP_NAMESPACE === 'biz-tecma-prod') {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.eu',
    service: 'floorplanning-sell',
    env: process.env.REACT_APP_NAMESPACE,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
}

const App = ({ forceLogin, socket, componentMode, backToNeurosales, setUserInfo }) => {
  const store = useStore();
  const { i18n } = useTranslation();

  const [updateUser] = useMutation(Client.UPDATE_USER);

  useTracking();

  const languageChangeHandler = (language) => {
    if (i18n.language !== language) {
      i18n.changeLanguage(language);
      store.setLanguage(language);
      moment.locale(language);

      // Se siamo degli utenti loggati, salvo a DB la lingua selezionata
      // Controllo l'esistenza sia dell'id che della mail per essere sicuro che sia un utente reale
      // loggato e non il cliente durante la videocall
      if (store?.loggedUser?.id && store.loggedUser.email) {
        updateUserLanguage(language);
      }
    }
  };

  const updateUserLanguage = async (lang) => {
    try {
      const { language, locale } = getLanguageAndLocale(lang);

      const updateData = { ...store.loggedUser };
      updateData.language = language;
      updateData.locale = locale;
      delete updateData.id;
      delete updateData.createdOn;
      delete updateData.__typename;

      await updateUser(Client.UPDATE_USER_DEFAULT_OPTIONS(store.loggedUser.id, updateData));
    } catch (error) {
      console.error('Error updating User language: ', error);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (document.getElementById('white-loader')) {
        document.getElementById('white-loader').style.display = 'none';
      }
    }, 10 * 1000);
    // Controllo che la lingua impostata nella libreria sia comprensiva di locale
    let initialLanguage = i18n.language;

    if (!initialLanguage.includes('-')) {
      // in caso contrario tento di aggiungerci il locale
      initialLanguage = getLanguage(initialLanguage);
    }

    i18n.changeLanguage(initialLanguage);
    store.setLanguage(initialLanguage);
    moment.locale(initialLanguage);

    store.setLanguageChangeHandler(languageChangeHandler);

    if (socket) {
      // FLOORPLANNING CUSTOM QUOTATION CHANGE
      socket.on('quotation-event', (event) => {
        if (event?.quotation) {
          try {
            store.setCustomQuote(event.quotation);
            store.setActivePromos(event.activePromos);
            store.setShowRata(event.showRata);
          } catch (e) {
            console.error(e);
          }
        }
      });
    }
    if (componentMode) {
      // Se siamo in modalità componente, lo salviamo nello store per renderlo accessibile ovunque
      store.setComponentMode(true);
      store.setBackToNeurosales(backToNeurosales);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (setUserInfo) {
      setUserInfo(store.loggedUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.loggedUser]);

  return (
    <React.Suspense fallback={showLoading()}>
      <CurrencyProvider>
        <JwtChecker />
        <Icons />
        {forceLogin && !CookiesUtils.getClientID() && <Redirect from='*' to={`/login`} />}
        <Switch>
          <Route
            exact
            path={`/login`}
            name='Login Page'
            render={(props) => {
              return <Login {...props} />;
            }}
          />
          <Route
            exact
            path={`/404`}
            name='Page 404'
            render={(props) => {
              return <Page404 {...props} />;
            }}
          />
          <Route
            path={`/`}
            name='Home'
            render={(props) => {
              return <Layout {...props} />;
            }}
          />
        </Switch>
      </CurrencyProvider>
    </React.Suspense>
  );
};

export default ({ basename, forceLogin, socket, componentMode, hostname, backToNeurosales }) => {
  const store = useStore();
  const [i18nLoading, setI18nLoading] = useState(true);
  const [baseUrl, setBaseUrl] = useState(null);

  const { data, loading, called } = useQuery(Client.GET_PROJECT_INFO, Client.GET_PROJECT_INFO_DEFAULT_OPTIONS(componentMode ? hostname : DetectUrl()));
  useEffect(() => {
    if (data && called && data.getProjectInfoByHost) {
      const project = data.getProjectInfoByHost;
      const projectTagManagerArgs = {
        gtmId: project.gtmId,
        fbPixelId: project.fbPixelId,
      };
      store.setAssetsByObject(project);
      setBaseUrl(project.baseurl);
      if (project.manifestConfig) generateManifest(project.manifestConfig);

      inizializeAnalytics(projectTagManagerArgs);

      modifyTitle(store.projectTitle);
      loadFavicon(store.projectDisplayName);
      loadCss(store.projectDisplayName);
      store.setWithDAS(store?.flowDesk?.some((step) => step.component === 'ComponentSwitcherDas' || step.component === 'FloorDas' || step.component === 'PlanimetryDas'));

      loadResources({
        id: project.id,
        displayName: project.displayName,
        languages: store.availableLanguages,
      }).finally(() => {
        setI18nLoading(false);
      });
    }

    if (data?.getProjectInfoByHost === null && !loading && called && data) {
      throw new Error('Missing project info data');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, called, data]);

  if (loading || i18nLoading || !baseUrl) {
    return <></>;
  }

  let browserRouterBasename = ``;
  if (basename) {
    // from neurosales
    browserRouterBasename = `${basename}`;
  } else if (!basename && baseUrl) {
    // FP standalone
    browserRouterBasename = `${baseUrl}`;
  }

  // Se Floorplanning è utilizzato come componente, uso un basename composto utilizzando quello passato come prop
  return baseUrl ? (
    <>
      {!componentMode && <ServiceWorkerWrapper baseUrl={baseUrl} />}
      <div className='white-loader' id='white-loader'>
        <Loader type='RevolvingDot' color='#808080' height={100} width={100} />
      </div>
      <BrowserRouter basename={browserRouterBasename}>
        <App componentMode={componentMode} basename={basename} forceLogin={forceLogin} socket={socket} hostname={hostname} backToNeurosales={backToNeurosales} />
      </BrowserRouter>
    </>
  ) : (
    <></>
  );
};
